import clsx from 'clsx';
import * as React from 'react';
import { Cell, Image } from '@vkontakte/vkui';
import {
  Icon24RemoveCircle,
  Icon24LinkCircleFilled,
  Icon12EyeSlashOutline,
  Icon20FavoriteOutline,
} from '@vkontakte/icons';

type Props = {
	id?: string;
  previewUrl?: string;
  index: number;
  title: string;
  subtitle?: string;
  link?: string;
  isActive?: boolean;
  isOldCard?: boolean;
  rating?: number;
  onDelete?: (index: number, id?: string) => void;
};

/**
 * Компонент карточки, который используется в
 * редактируемых списках (например в ранжировании)
 */
export const CellEditing = ({ id, previewUrl, index, title, subtitle, link, rating, isActive: isActiveInitial, isOldCard, onDelete }: Props): React.ReactElement => {
  const handleDelete = React.useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    onDelete?.(index, id);
  }, [index, onDelete, id]);

  const handleOpenLink = React.useCallback(() => {
    if (!link) {
      return;
    }

    window.open(
      link,
      '_blank',
    )
  }, [link]);

	const isActive = isActiveInitial === undefined || isActiveInitial;

	return (
		<Cell
			className={clsx(
				'group w-1/2 rounded-md bg-color-card-grey py-1 transition-all hover:bg-color-light-blue-40',
				{
					'bg-color-card-grey':
          isActive && isOldCard,
					'bg-red-100 hover:bg-red-200':
						!isActive && isOldCard,
					'outline-dashed outline-1 outline-color-nav-items-blue': !isOldCard,
				},
			)}
			hasHover={false}
			hasActive={false}
			before={
				previewUrl ? (
          <Image size={48} src={previewUrl} borderRadius="l" />
				) : (
          <Image size={48} borderRadius="l">
						<Icon12EyeSlashOutline />
					</Image>
				)
			}
			after={
				<div className="flex flex-row items-center align-middle">
					{rating && rating !== 0 ? (
						<div className="absolute right-20 flex flex-row items-center align-middle">
							<>
								<Icon20FavoriteOutline
									width={24}
									height={24}
									color="#5181B8"
								/>
								<span className="text-base">{rating || null}</span>
							</>
						</div>
					) : null}

          {link && (
            <Icon24LinkCircleFilled
              className="absolute right-10 top-1/2 -translate-y-1/2 transform cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100"
              width={27}
              height={27}
              color="#5181B8"
              onClick={handleOpenLink}
            />
          )}
					<Icon24RemoveCircle
						className="absolute right-2 top-1/2 -translate-y-1/2 transform cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100"
						width={24}
						height={24}
						color={'#e64646'}
						onClick={handleDelete}
					/>
				</div>
			}
		>
			<div className="flex flex-col">
				<span className="font-medium">{`${title.substring(0, 45)}${title.length > 45 ? '...' : ''}`}</span>
				<span className="font-light text-color-steel-gray-500">
					{subtitle || 'Тип не указан'}
				</span>
			</div>
		</Cell>
	);
};
