const MINIAPP_PATH_PREFIX = 'path='

export type GetClearUrlParams = {
	obj: 'event' | 'place' | 'person';
	type?: string;
	slugOrId: string;
	app: 'web' | 'miniapp';
}

export const getClearUrl = ({
	obj,
	type,
	slugOrId,
	app,
}: GetClearUrlParams): string | null => {
	if (app === 'web') {
		let webUrl = process.env.REACT_APP_SLUG_BASE_URL;

		if (!webUrl) {
			return null;
		}

		if (obj === 'event') {
			webUrl += '/moskva';
			type === 'Кино' ? (webUrl += '/movie/') : (webUrl += '/event/');
		}

		if (obj === 'place') {
			webUrl += '/moskva';
			type === 'Кинотеатр' ? (webUrl += '/cinema/') : (webUrl += '/place/');
		}

		if (obj === 'person') {
			webUrl += '/person/';
		}

		return webUrl + slugOrId;
	}

	if (app === 'miniapp') {
		let miniAppUrl = process.env.REACT_APP_MINIAPP_BASE_URL;

		if (!miniAppUrl) {
			return null;
		}

		if (obj === 'event') {
			miniAppUrl += `${MINIAPP_PATH_PREFIX}/poster/`;
			miniAppUrl +=
				type === 'Кино'
					? `movie-detail/${slugOrId}`
					: `activity-detail/${slugOrId}`;
		}

		if (obj === 'place') {
			miniAppUrl += `${MINIAPP_PATH_PREFIX}/poster/`;
			miniAppUrl +=
				type === 'Кинотеатр'
					? `cinema-detail/${slugOrId}`
					: `venue-detail/${slugOrId}`;
		}

		if (obj === 'person') {
			miniAppUrl += `${MINIAPP_PATH_PREFIX}/poster/person/${slugOrId}`;
		}


		return miniAppUrl;
	}

	return null;
};
