import { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Div } from '@vkontakte/vkui';
import { Icon24ListNumberOutline } from '@vkontakte/icons';
import { Reorder } from 'framer-motion';
import { IRankingEvent } from './types.rankings';
import { ADMIN_EVENTS_ROUTE } from 'lib/constants';
import { CellEditing } from 'components/common/CellEditing';
import * as React from 'react';

interface IEventsRankingCardsProps {
	cards: IRankingEvent[];
	oldCards: IRankingEvent[];
	setCards: Dispatch<SetStateAction<IRankingEvent[]>>;
	onDelete: (index: number) => void;
}

const RankingCards: FC<IEventsRankingCardsProps> = ({
	cards,
	oldCards,
	setCards,
	onDelete,
}) => {
	const handleDelete = (index: number) => {
		onDelete(index);
	};

	const handleOrderInput = (
		event: ChangeEvent<HTMLInputElement>,
		index: number,
	) => {
		const newIndex = parseInt(event.target.value) - 1;
		if (newIndex >= 0 && newIndex < cards.length && newIndex !== index) {
			const newCards = [...cards];
			const [movedItem] = newCards.splice(index, 1);
			newCards.splice(newIndex, 0, movedItem);
			setCards(newCards);
		}
	};

	const isOldCard = (id: string) =>
		oldCards.some((oldCard) => oldCard.id === id);

	return (
		<Div>
			<Reorder.Group
				className="flex flex-col gap-1"
				values={cards}
				onReorder={setCards}
			>
				{cards.map((item, index) => (
					<Reorder.Item id={item.id} key={item.id} value={item}>
						<div className="relative flex gap-3">
							<input
								type="text"
								className="mt-4 max-h-8 w-8 rounded-md bg-color-card-grey py-px text-center text-color-steel-gray-500 focus:outline-none focus:ring-2 focus:ring-color-nav-items-blue"
								value={index + 1}
								onChange={(event) => handleOrderInput(event, index)}
							/>
							<CellEditing
								index={index}
								isOldCard={isOldCard(item.id)}
								isActive={item.has_active_seances && item.is_enabled}
								previewUrl={item.preview_url}
								rating={item.rating}
								link={ADMIN_EVENTS_ROUTE + `/edit/${item.id}`}
								onDelete={handleDelete}
								title={item.name}
								subtitle={item.category_name}
							/>
							<div>
								<Icon24ListNumberOutline
									key={item.id}
									color="#E5EBF1"
									width={24}
									height={24}
									className="reorder-handle mt-5 cursor-ns-resize"
								/>
							</div>
						</div>
					</Reorder.Item>
				))}
			</Reorder.Group>
		</Div>
	);
};

export default RankingCards;
