import * as React from 'react';
import { TextTooltip } from '@vkontakte/vkui/dist/components/TextTooltip/TextTooltip';
import { Text } from '@vkontakte/vkui';
import {
  countUniqueValues,
  createStringFromObjArray,
} from 'lib/utils/arrayOfObjects';
import { Cell, CellProps } from './Cell';

type Props<E extends Record<string, string>> = {
  /**
   * По какому ключу считаем значения
   */
  keyToCountBy: keyof E;
  entities: E[];
};

/**
 * Получает массив объектов и ключ, по которому нужно считать уникальные значения
 * - Если уникальных значений 1, то возвращает строку с этим значением
 * - Если уникальных значений больше 1, то возвращает строку вида "Москва +2", 
 * остальные значения отображаются в тултипе
 */
export const CellToCountByKey = <E extends Record<string, string>>({ entities, keyToCountBy, ...cellProps }: Props<E> & CellProps): React.ReactElement => {
  /**
   * Кол-во уникальных значений
   */
  const countedUniqueValues = countUniqueValues(entities, keyToCountBy);

  /**
   * Основной текст ячейки (первая / единственная сущность)
   */
  const label = entities[0]?.[keyToCountBy] || '-';

  return (
    <Cell {...cellProps}>
      {countedUniqueValues > 1 ? (
        <TextTooltip
          className="max-w-lg"
          text={createStringFromObjArray(
            entities,
            keyToCountBy,
          )}
        >
          <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
            {label} +{countedUniqueValues - 1}
          </Text>
        </TextTooltip>
      ) : (
        <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
          {label}
        </Text>
      )}
    </Cell>
  );
};
