import { useState, useCallback, useEffect } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import apiService from 'lib/api/api';
import { useSnackbar } from './useSnackbar';
import { IResponseError, IResponseFieldError } from 'lib/types';
import { parseErrorMessage } from 'lib/utils/parseErrorMessage';
import { useAuth } from 'providers/AuthProvider';
import { authService } from 'lib/api/authService';
import { getUserToken } from 'lib/utils/authUtils';
import { withQueryString } from 'lib/utils/withQueryString';

const useFetch = <T>() => {
	const [data, setData] = useState<T | null>(null);
	const [error, setError] = useState<AxiosError<IResponseError> | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { setSnackbarContent } = useSnackbar();
	const { setUser } = useAuth();

	const handleRefreshToken = useCallback(async () => {
		const refreshToken = getUserToken('refresh');

		if (refreshToken) {
			try {
				const apiResponse = await authService.refreshToken(`/auth/refresh`, {
					refresh_token: refreshToken,
				});
				const userData = apiResponse.data;
				localStorage.setItem('user', JSON.stringify(userData));
				setUser(userData);
			} catch (error) {
				setSnackbarContent({
					type: 'error',
					message: 'Ошибка при авторизации',
				});
				localStorage.removeItem('user');
				setUser(null);
			}
		}
	}, [setSnackbarContent, setUser]);

	const fetchData = useCallback(async ({
		path,
		method = 'get',
		body,
		params,
		onSuccessMessage,
		onErrorMessage = 'Что-то пошло не так',
	}: {
		path: string;
		method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
		body?: T;
		params?: Record<string, unknown>;
		onSuccessMessage?: string;
		onErrorMessage?: string;
	}) => {
		try {
			setLoading(true);

			const pathWithParams = withQueryString({ path, params });

			let response: AxiosResponse<T>;

			switch (method) {
				case 'post':
					response = await apiService.post<T>(pathWithParams, body);
					break;
				case 'put':
					response = await apiService.put<T>(pathWithParams, body);
					break;
				case 'patch':
					response = await apiService.patch<T>(pathWithParams, body);
					break;
				case 'delete':
					body
						? (response = await apiService.deleteAll<T>(pathWithParams, body))
						: (response = await apiService.delete<T>(pathWithParams));
					break;
				default:
					response = await apiService.get<T>(pathWithParams);
			}

			setData(response.data);
			setError(null);

			onSuccessMessage &&
				setSnackbarContent({
					type: 'success',
					message: onSuccessMessage,
				});
		} catch (error) {
			const typedError = error as AxiosError<IResponseError>;

			if (
				typedError.response?.data.error_message ===
					'Signature verification failed' ||
				typedError.response?.data.error_message === 'Signature has expired'
			) {
				handleRefreshToken();
			}

			if (typedError.code === 'ERR_NETWORK') {
				setError(typedError);

				return setSnackbarContent({
					type: 'error',
					message: onErrorMessage,
				});
			}

			const errorResponse = typedError.response?.data;

			const parsedErrorResponse =
				errorResponse?.field_errors && errorResponse?.field_errors.length > 0
					? errorResponse
					: parseErrorMessage(typedError);

			const parsedErrorFields =
				parsedErrorResponse?.field_errors?.map((item: IResponseFieldError) => {
					const match = item.message?.match(/<([^>]*)>/);
					const fieldName = match ? match[1] : '';

					return {
						field: item.field,
						message: item.message
							? item.message.includes('already exists')
								? `${fieldName} уже существует`
								: item.message
							: 'Неизвестная ошибка',
					};
				}) || [];

			setError({
				...typedError,
				response: {
					data: {
						...typedError.response?.data,
						parsedErrorFields,
					},
					// eslint-disable-next-line
				} as any,
			});

			setSnackbarContent({
				type: 'error',
				message:
					parsedErrorResponse.error_message === 'Unique name violated'
						? onErrorMessage
						: parsedErrorResponse.error_message,
			});

			return {
				errorData: typedError,
				errorResponse: parsedErrorResponse,
			};
		} finally {
			setLoading(false);
		}
	}, [handleRefreshToken, setSnackbarContent]);

	const resetData = useCallback(() => {
		setData(null);
		setError(null);
		setLoading(false);
	}, []);

	return { data, error, loading, fetchData, resetData };
};

export default useFetch;
