import * as React from 'react';
import { useFetchPersonData } from './hooks/useFetchPersonData';
import { PersonSelection } from 'components/complex/PersonSelection';
import { IEventPerson } from 'components/Events/types.events';
import { usePatchPersonData } from './hooks/usePatchPersonData';

export const PersonsRelatedPersons = (): React.ReactElement => {
	const { data, loading, fetchData } = useFetchPersonData('person');
	const { patching, patchData } = usePatchPersonData();

	const currentPersons = React.useMemo<IEventPerson[]>(
		() =>
			data?.map(({ entity }) => ({
				id: entity.id,
				full_name: entity.full_name,
			})) ?? [],
		[data],
	);

	const handleUpdate = React.useCallback((newPersonIds: string[]) => {
		patchData({
			entities: newPersonIds,
			entityType: 'person',
			onSuccess: () => {
				fetchData();
			},
		});
	}, []);

	return (
		<PersonSelection
			currentPersons={currentPersons}
			applying={loading || patching}
			onApply={handleUpdate}
		/>
	);
};
