import { Text as VKText, TextProps } from '@vkontakte/vkui';
import clsx from 'clsx';
import * as React from 'react';

export const Text = ({ children, className }: TextProps): React.ReactElement => {
  return (
    <VKText className={clsx("max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8", className)}>
      {children}
    </VKText>
  );
};
