import * as React from 'react';
import { Div, Spinner } from '@vkontakte/vkui';

export const Loader = (): React.ReactElement => {
  return (
    <Div style={{ width: '100%', height: '400px' }}>
      <Spinner size="medium" className="spinner" />
    </Div>
  );
};
