import { DEFAULT_PAGE_SIZE } from './constants';
import { withSearchParams } from './utils/withSearchParams';

export type PersonEntityType = 'event' | 'place' | 'person';

type PaginationParams = {
	page?: number;
	pageSize?: number;
};

export const ENDPOINTS = {
	personEntities: {
		getUrl: ({
			personId,
			entityType,
		}: {
			personId: string;
			entityType: PersonEntityType;
		}) =>
			`/person/persons/${personId}/link/?entity_type=${entityType}`,
	},
	personPatch: {
		getUrl: (personId: string) => `/person/persons/${personId}/`,
		method: 'patch',
	},
	searchPersons: {
		getUrl: ({
			query,
			page = 0,
			pageSize = DEFAULT_PAGE_SIZE,
		}: { query?: string } & PaginationParams) =>
			withSearchParams('/person/persons/', {
				page,
				page_size: pageSize,
				q: query,
			}),
	},
	searchEvents: {
		getUrl: ({
			query,
			page = 0,
			pageSize = DEFAULT_PAGE_SIZE,
		}: { query?: string } & PaginationParams) =>
			withSearchParams('/search/events', {
				page,
				page_size: pageSize,
				q: query,
			}),
	},
	searchPlaces: {
		getUrl: ({
			query,
			page = 0,
			pageSize = DEFAULT_PAGE_SIZE,
		}: { query?: string } & PaginationParams) =>
			withSearchParams('/search/places', {
				page,
				page_size: pageSize,
				q: query,
			}),
	},
} satisfies Record<
	string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	{ getUrl: (...args: any[]) => string; method?: 'get' | 'post' | 'patch' }
>;
