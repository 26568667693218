import React, { useEffect, useRef, useState } from 'react';
import { Icon16TableOutline } from '@vkontakte/icons';
import { Button, Checkbox, Div } from '@vkontakte/vkui';
import { ITableHeader } from 'lib/types';

interface ITableSetupProps {
  headers: ITableHeader[];
  onActiveHeadersChange: (activeHeaders: ITableHeader[]) => void;
  tableId: string;
}

export default function TableSetup({
  headers,
  onActiveHeadersChange,
  tableId,
}: ITableSetupProps) {
  const [isShow, setIsShow] = useState<boolean>(false);
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>(headers);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const saveColumnOrder = (headers: ITableHeader[], tableId: string) => {
    localStorage.setItem(`columnOrder_${tableId}`, JSON.stringify(headers));
  };

  const getColumnOrder = (tableId: string): ITableHeader[] | null => {
    const storedHeaders = localStorage.getItem(`columnOrder_${tableId}`);
    return storedHeaders ? JSON.parse(storedHeaders) : null;
  };

  const handleCheckboxChange = (header: ITableHeader) => {
    setActiveHeaders((prev) => {
      const isActive = prev.some((h) => h.value === header.value);
      const newActiveHeaders = isActive
        ? prev.filter((h) => h.value !== header.value)
        : [...prev, header].sort((a, b) => {
            return (
              headers.findIndex((h) => h.value === a.value) -
              headers.findIndex((h) => h.value === b.value)
            );
          });

      saveColumnOrder(newActiveHeaders, tableId);
      onActiveHeadersChange(newActiveHeaders);
      return newActiveHeaders;
    });
  };

  useEffect(() => {
    const storedHeaders = getColumnOrder(tableId);
    if (storedHeaders) {
      setActiveHeaders(
        storedHeaders.sort((a, b) => {
          return (
            headers.findIndex((h) => h.value === a.value) -
            headers.findIndex((h) => h.value === b.value)
          );
        }),
      );
    } else {
      setActiveHeaders(headers);
    }
  }, [headers, tableId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="ml-auto z-10">
      <Button
        onClick={() => setIsShow((prev) => !prev)}
        className="vkui-edit-button-primary min-w-fit"
        size="m"
        before={<Icon16TableOutline width={20} height={20} />}
        after={
          <div
            style={{ color: '#5181B8' }}
            className="flex items-center justify-center rounded-full bg-white w-5 h-5 text-xs"
          >
            {activeHeaders.length}
          </div>
        }
      >
        Настроить таблицу
      </Button>
      {isShow && (
        <div ref={dropdownRef}>
          <Div className="absolute right-2">
            <ul className="bg-white p-2 flex flex-col gap-2 shadow-md rounded-md max-h-lg overflow-y-auto custom-scrollbar">
              {headers.map((header) => (
                <li key={header.value} className="whitespace-nowrap">
                  <Checkbox
                    name={header.value}
                    checked={activeHeaders.some(
                      (h) => h.value === header.value,
                    )}
                    onChange={() => handleCheckboxChange(header)}
                  >
                    <span className="text-nav">{header.label}</span>
                  </Checkbox>
                </li>
              ))}
            </ul>
          </Div>
        </div>
      )}
    </div>
  );
}
