import * as React from 'react';
import { Spinner, Placeholder } from '@vkontakte/vkui';

type Props = React.PropsWithChildren<{
	isLoading?: boolean;
	isError?: boolean;
	isEmpty?: boolean;
	errorText?: string;
	emptyText?: string;
}>;

export const WithMeta = ({
	isEmpty,
	isError,
	isLoading,
	children,
	errorText = 'Произошла ошибка',
	emptyText = 'Ничего не найдено'
}: Props): React.ReactElement => {
	if (isLoading) {
		return <Placeholder icon={<Spinner size="medium" className="spinner" />} />;
	}

	if (isEmpty) {
		return <Placeholder>{emptyText}</Placeholder>;
	}

	if (isError) {
		return <Placeholder>{errorText}</Placeholder>;
	}

	return <>{children}</>;
};
