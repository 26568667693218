import React, { ChangeEventHandler, KeyboardEvent } from 'react';
import { Input, Spinner } from '@vkontakte/vkui';
import { Icon16SearchOutline, Icon16Clear } from '@vkontakte/icons';
import clsx from 'clsx';

interface IInputSearch {
	value: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
	type?: string;
	onSubmit: () => void;
	onClick?: () => void;
	onFocus?: VoidFunction;
	onBlur?: VoidFunction;
	placeholder?: string;
	allowClearButton?: boolean;
	width?: string;
	loading?: boolean;
	className?: string;
}

const InputSearch = ({
	value,
	onChange,
	type = 'text',
	onSubmit,
	placeholder = 'Поиск',
	onClick,
	allowClearButton = false,
	width = '200px',
	onFocus,
	onBlur,
	loading,
	className,
}: IInputSearch) => {
	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			onSubmit();
		}
	};

	const handleClear = () => {
		const event = {
			target: { value: '' },
		} as React.ChangeEvent<HTMLInputElement>;
		onChange(event);
	};

	return (
		<Input
			onKeyDown={handleKeyDown}
			type={type}
			before={<Icon16SearchOutline fill="#99A2AD" />}
			style={{ width: width, height: '42px' }}
			placeholder={placeholder}
			size={15}
			className={clsx("input-style bg-color-white", className)}
			value={value}
			onChange={onChange}
			onClick={onClick}
			after={
				loading ? (
					<Spinner size="small" />
				) : (
					allowClearButton &&
					value && (
						<Icon16Clear className="cursor-pointer" onClick={handleClear} />
					)
				)
			}
			onFocus={onFocus}
			onBlur={onBlur}
		/>
	);
};

export default InputSearch;
