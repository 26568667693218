import { IEventPerson } from "components/Events/types.events";
import { SelectedType } from './types';
import { Icon24RemoveCircle } from '@vkontakte/icons';
import { Cell, Tappable } from '@vkontakte/vkui';
import clsx from "clsx";
import * as React from 'react';

export const SelectedPersonItem = ({
  person,
  onDelete,
  type,
}: {
  person: IEventPerson;
  type: SelectedType;
  onDelete?: (person: IEventPerson, type: SelectedType) => void;
}): React.ReactElement => {
  const handleDelete = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      onDelete?.(person, type);
    },
    [person, type, onDelete],
  );

  return (
    <Cell
      className={clsx(
        'group min-w-fit rounded-md bg-color-card-grey text-color-steel-gray-500',
        type === 'new' &&
          'outline-dashed outline-1 outline-color-nav-items-blue',
      )}
      after={
        <Tappable className="rounded" onClick={handleDelete}>
          <Icon24RemoveCircle
            className="absolute -right-5 -top-5 -translate-y-1/2 transform cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100"
            width={28}
            height={28}
            color="#e64646"
          />
        </Tappable>
      }
    >
      {person.full_name}
    </Cell>
  );
};
