import clsx from 'clsx';
import * as React from 'react';

export type CellProps = {
  className?: string;
  paddingY?: 1 | 2;
} & React.HTMLProps<HTMLTableDataCellElement>;

export const Cell = ({
  className,
  paddingY = 2,
  ...props
}: CellProps): React.ReactElement => {
  return (
    <td
      className={clsx(
        paddingY === 2 && 'py-2',
        paddingY === 1 && 'py-1',
        className,
      )}
      {...props}
    />
  );
};
