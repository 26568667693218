import * as React from 'react';
import { Head } from './Head';
import { HeadCell } from './HeadCell';
import { Body } from './Body';
import { Row } from './Row';
import { Cell } from './Cell';
import { CellToCountByKey } from './CellToCountByKey';
import { Loader } from './Loader';
import { Text } from './Text';
import { Div, Placeholder } from '@vkontakte/vkui';
import clsx from 'clsx';

type Props = {
  isEmpty?: boolean;
  isLoading?: boolean;
  before?: React.ReactNode;
  className?: string;
} & React.HTMLProps<HTMLDivElement>;

const Table = ({
  children,
  isEmpty,
  isLoading,
  before,
  className,
  ...props
}: Props): React.ReactElement => {
  if (isLoading) {
    return <Loader />;
  }

  if (isEmpty) {
    return <Placeholder>Ничего не найдено</Placeholder>;
  }

  return (
    <>
      {before && <Div className="flex items-center gap-2">{before}</Div>}
      <div
        className={clsx('custom-scrollbar overflow-auto pb-2', className)}
        {...props}
      >
        <table className="w-full">{children}</table>
      </div>
    </>
  );
};

Table.Head = Head;
Table.HeadCell = HeadCell;
Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;
Table.CellToCountByKey = CellToCountByKey;
Table.Loader = Loader;
Table.Text = Text;

export { Table };
