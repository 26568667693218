import * as React from 'react';

type Params<E extends { id: string }> = {
  currentEntities: E[];
};

export const useEditList = <E extends { id: string }>({ currentEntities }: Params<E>) => {
  const [oldEntities, setOldEntities] = React.useState<E[]>([]);
  const [newEntities, setNewEntities] = React.useState<E[]>([]);

  /**
   * Добавляет новые сущности в список
   * (если сущность уже была добавлена, то она
   * не будет добавлена во второй раз)
   */
  const addNew = React.useCallback((value: E[] | E, { onFoundDuplicates }: { onFoundDuplicates?: VoidFunction } = {}) => {
    const entities = Array.isArray(value) ? value : [value];

    /**
     * В списке сущностей, которые добавляются
     * есть сущности, которые были добавлены ранее
     */
    let foundDuplicates = false;

    setNewEntities(prev => {
      const unique = entities.reduce<E[]>((result, entity) => {
        if (prev.some(({ id }) => id === entity.id) || oldEntities.some(({ id }) => id === entity.id)) {
          foundDuplicates = true;

          return result;
        }

        result.push(entity);

        return result;
      }, []);

      return [...prev, ...unique];
    });

    if (foundDuplicates) {
      onFoundDuplicates?.();
    }
  }, [oldEntities]);

  const removeNew = React.useCallback((id: E['id']) => {
    setNewEntities(prev => prev.filter(entity => entity.id !== id));
  }, []);

  const removeOld = React.useCallback((id: E['id']) => {
    setOldEntities(prev => prev.filter(entity => entity.id !== id));
  }, []);

  React.useEffect(() => {
    setOldEntities(currentEntities);
    setNewEntities([]);
  }, [currentEntities]);

  const total = oldEntities.length + newEntities.length;
  const isEmpty = total === 0;

  return { oldEntities, newEntities, addNew, removeNew, removeOld, isEmpty };
};