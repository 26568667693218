/**
 * Склеивает значения с переданным ключом из массива объектов в строку через запятую
 * 
 * @example createStringFromObjArray([{ city: 'msk' }, { city: 'spb' }], 'city'); // 'msk, spb'
 * @example createStringFromObjArray([{ city: 'msk' }, { city: 'msk' }], 'city'); // 'msk'
 */
export const createStringFromObjArray = <T>(arr: T[], key: keyof T): string => {
  const uniqueValues = new Set<string>();

  arr.forEach((obj) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      uniqueValues.add(String(obj[key]));
    }
  });

  return Array.from(uniqueValues).join(', ');
};

/**
 * Считает количество уникальных значений с переданным ключом в массиве объектов
 * 
 * @example countUniqueValues([{ city: 'msk' }, { city: 'spb' }], 'city'); // 2
 * @example countUniqueValues([{ city: 'msk' }, { city: 'msk' }], 'city'); // 1
 */
export const countUniqueValues = <T>(arr: T[], key: keyof T): number => {
  const uniqueValues = new Set<string>();

  arr.forEach((obj) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      uniqueValues.add(String(obj[key]));
    }
  });

  return uniqueValues.size;
};
