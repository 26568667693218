import * as React from 'react';
import { Button, ButtonGroup, Div, FormItem } from '@vkontakte/vkui';
import { MODAL_ID } from './constants.persons';
import { PATHS } from 'lib/constants';
import { useFetchPersonData } from './hooks/useFetchPersonData';
import { Icon20AddCircle } from '@vkontakte/icons';
import { usePatchPersonData } from './hooks/usePatchPersonData';
import { CellEditing } from 'components/common/CellEditing';
import { useEditList } from 'lib/hooks/useEditList';
import { EventSelectionModal } from 'components/complex/EventSelectionModal';
import { PersonEventShort } from './types.persons';
import { WithMeta } from 'components/common/WithMeta';
import { useSnackbar } from 'lib/hooks/useSnackbar';

type Props = {
  closeModal: VoidFunction;
  openModal: (
    id: string,
    config?: { element?: React.ReactNode | null },
  ) => void;
};

export const PersonsEvents = ({
  closeModal,
  openModal,
}: Props): React.ReactElement | null => {
  const { data, fetchData, loading } = useFetchPersonData('event');

  const { patchData, patching } = usePatchPersonData();

  const { setSnackbarContent } = useSnackbar();

  const currentEntities = React.useMemo<PersonEventShort[]>(
    () =>
      data?.map(({ entity }) => ({
        id: entity.id,
        title: entity.name,
        eventType: entity.event_type?.name,
        isActive: entity.is_active,
      })) ?? [],
    [data],
  );

  const { oldEntities, newEntities, removeNew, removeOld, addNew, isEmpty } =
    useEditList<PersonEventShort>({
      currentEntities,
    });

  const handleDeleteOld = React.useCallback((_: number, id?: string) => {
    if (!id) {
      return;
    }

    removeOld(id);
  }, []);

  const handleDeleteNew = React.useCallback((_: number, id?: string) => {
    if (!id) {
      return;
    }

    removeNew(id);
  }, []);

  const openAddModal = React.useCallback(() => {
    openModal(MODAL_ID.addEvents, {
      element: (
        <EventSelectionModal
          onSave={(events) => {
            closeModal();

            if (!events) {
              return;
            }

            addNew(
              events.map((event) => ({
                id: event.id,
                title: event.name,
                isActive: Boolean(event.is_enabled),
                eventType: event.event_type?.name,
              })),
              {
                onFoundDuplicates: () => {
                  setSnackbarContent({
                    type: 'error',
                    message: 'Некоторые мероприятия уже были добавлены',
                  });
                },
              },
            );
          }}
        />
      ),
    });
  }, [openModal, closeModal, newEntities, setSnackbarContent]);

  const handleSave = React.useCallback(() => {
    patchData({
      entityType: 'event',
      entities: [
        ...oldEntities.map(({ id }) => id),
        ...newEntities.map(({ id }) => id),
      ],
      onSuccess: () => {
        fetchData();
      },
    });
  }, [oldEntities, newEntities, patchData, closeModal, setSnackbarContent]);

  return (
    <>
      <FormItem>
        <Button
          mode="secondary"
          size="l"
          appearance="accent"
          onClick={openAddModal}
          before={<Icon20AddCircle width={18} />}
        >
          Добавить мероприятия
        </Button>
      </FormItem>
      <WithMeta
        isLoading={loading}
        isEmpty={isEmpty}
        emptyText="Нет связанных мероприятий"
      >
        <Div className="flex flex-col gap-1">
          {oldEntities.map((entity, index) => (
            <CellEditing
              id={entity.id}
              key={entity.id}
              index={index}
              title={entity.title}
              subtitle={entity.eventType}
              link={PATHS.eventEdit(entity.id)}
              isOldCard
              onDelete={handleDeleteOld}
              isActive={entity.isActive}
            />
          ))}
          {newEntities.map((entity, index) => (
            <CellEditing
              id={entity.id}
              key={entity.id}
              index={index}
              title={entity.title}
              subtitle={entity.eventType}
              link={PATHS.eventEdit(entity.id)}
              isOldCard={false}
              onDelete={handleDeleteNew}
              isActive={entity.isActive}
            />
          ))}
        </Div>
      </WithMeta>
      <Div>
        <ButtonGroup align="right" stretched>
          <Button
            className="vkui-edit-button-primary"
            mode="primary"
            size="m"
            appearance="accent"
            onClick={handleSave}
            loading={patching}
          >
            Сохранить
          </Button>
        </ButtonGroup>
      </Div>
    </>
  );
};
