import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
	Button,
	ButtonGroup,
	Div,
	Group,
	Text,
	Title,
	FormItem,
	Panel,
	PanelHeader,
	Tabs,
	TabsItem,
	View,
	Spinner,
	Header,
	File,
	Placeholder,
	Image,
	ModalRoot,
	ModalPage,
	Select,
	Checkbox,
	ModalCard,
	ChipsInput,
	CardGrid,
	Card,
} from '@vkontakte/vkui';
import {
	Icon24Attachments,
	Icon28PlayCircleFillGray,
	Icon20CropOutline,
	Icon20PicturePlusOutline,
	Icon20PictureOnSquareOutline,
	Icon20DeleteOutline,
	Icon20UserPenOutline,
} from '@vkontakte/icons';
import useFetch from 'lib/hooks/useFetch';
import { textEditorFormats, textEditorModules } from 'lib/utils/textEditor';
import ReactQuill from 'react-quill';
import ImageCropper from 'components/common/ImageCropper';
import { arraysAreEqual } from 'lib/utils/arrayСomparison';
import {
	ADMIN_EVENTS_ROUTE,
	ADMIN_REVIEWS_ROUTE,
	ASPECT_RATIO,
	MIN_DIMENSION,
} from 'lib/constants';
import { IChip } from 'lib/types';
import ImageGallery from 'components/common/ImageGallery';
import {
	handleFileSelection,
	handleImageChange,
	handleImageUrl,
	handleOrderInput,
} from 'lib/utils/imageFile.utils';
import { ApiResponse } from 'lib/api/api';
import EventSeances from '../EventSeances';
import { useSnackbar } from 'lib/hooks/useSnackbar';
import { findObjectsDiffs } from 'lib/utils/findObjectsDiffs';
import { NumberInput } from 'components/common/NumberInput';
import { replaceEmptyWithNull } from 'lib/utils/replaceEmptyWithNull';
import { getUserRole } from 'lib/utils/authUtils';
import { IEventEdit, IEventPerson, TabId } from '../types.events';
import { eventBlank, eventFieldNameMap, tabs } from '../constants.events';
import useFormItems from 'lib/hooks/useFormItems';
import { IType } from 'components/directories/TypesDirectory/types.typesDirectory';
import { IEventLabel } from 'components/directories/EventLabelsDirectory/types.eventLabelsDirectory';
import PanelTitle from 'components/common/PanelTitle';
import EventFormItem from 'components/common/EventFormItem';
import EventPersons from '../EventPersons';
import { parseDateFrom } from 'lib/utils/date.utils';
import EventLinks from '../EventLinks';
import getErrorMessage from 'lib/utils/getErrorMessage';
import { SelectGenres } from './components/SelectGenres';

//todo: методы предполагают пагинацию, но она не реализована. Нужна ли?
const PAGE_SIZE_LABEL = 50;
const PAGE_SIZE_CATEGORIES = 50;

const EventsEdit: FC = () => {
	const [, setSearchParams] = useSearchParams();
	const userRole = getUserRole();
	const navigate = useNavigate();
	const [selectedTab, setSelectedTab] = useState<TabId>(() => {
		const saveTab = sessionStorage.getItem('selectedEventTab');
		return saveTab && saveTab in TabId ? (saveTab as TabId) : TabId.info;
	});
	const { fetchData: patchData } = useFetch();
	const { data, loading, fetchData } = useFetch<IEventEdit>();
	const {
		data: categoryOptionsData,
		loading: categoryOptionsLoading,
		fetchData: categoryOptionsFetch,
	} = useFetch<ApiResponse<IType[]>>();
	const {
		data: topLabelOptionsData,
		loading: topLabelOptionsLoading,
		fetchData: topLabelOptionsFetch,
	} = useFetch<ApiResponse<IEventLabel[]>>();
	const {
		data: bottomLabelOptionsData,
		loading: bottomLabelOptionsLoading,
		fetchData: bottomLabelOptionsFetch,
	} = useFetch<ApiResponse<IEventLabel[]>>();
	const [eventData, setEventData] = useState<IEventEdit>(eventBlank);
	const { formData, isError, errorItems, handleChange } =
		useFormItems<IEventEdit>({
			initialValues: eventData,
		});
	const {
		id,
		name,
		age_category,
		organizer,
		vk_url,
		rating,
		provider_id,
		provider,
		trailer,
		poster,
		source_owner,
		changed_from_admin,
		is_pushkin_card,
		changed_from_admin_at,
	} = formData;
	const { setSnackbarContent } = useSnackbar();
	const { eventId, methodType } = useParams();
	const [openModal, setOpenModal] = useState<string>('');
	const [newDescription, setNewDescription] = useState<string>('');
	const [hasNewDescription, setHasNewDescription] = useState<boolean>(false);
	const [imageSrc, setImageSrc] = useState<string>('');
	const [newCover, setNewCover] = useState<string>('');
	const [newCoverOriginal, setNewCoverOriginal] = useState<string>('');
	const [newYear, setNewYear] = useState<number | null>(null);
	const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
	const [newCategory, setNewCategory] = useState<{
		id: string | null;
		name: string;
	}>({
		id: '',
		name: '',
	});
	const [prevCategory, setPrevCategory] = useState<{
		id: string | null;
		name: string;
	}>({
		id: '',
		name: '',
	});
	const [allCategories, setAllCategories] = useState<IType[]>([]); //todo: избавиться. Просто для перекладывания из categoryOptionsData.results
	const [categoryOptions, setCategoryOptions] = useState<IChip[]>();
	const [cropError, setCropError] = useState<string>('');
	const [displayedImageIndex, setDisplayedImageIndex] = useState<number>(0);
	const [selectedImages, setSelectedImages] = useState<string[]>([]);
	const [isNewEnabled, setIsNewEnabled] = useState<boolean>(false);
	const [newCountries, setNewCountries] = useState<IChip[]>([]);
	const [newGenres, setNewGenres] = useState<IChip[]>([]);
	const [newTopLabel, setNewTopLabel] = useState<{
		id: string | null;
		name: string;
	}>({
		id: '',
		name: '',
	});
	const [allTopLabels, setAllTopLabels] = useState<IEventLabel[]>([]); //todo: избавиться. Просто для перекладывания из topLabelOptionsData.results
	const [topLabelOptions, setTopLabelOptions] = useState<IChip[]>([]);
	const [newBottomLabel, setNewBottomLabel] = useState<{
		id: string | null;
		name: string;
	}>({
		id: '',
		name: '',
	});
	const [allBottomLabels, setAllBottomLabels] = useState<IEventLabel[]>([]); //todo: избавиться. Просто для перекладывания из bottomLabelOptionsData.results
	const [bottomLabelOptions, setBottomLabelOptions] = useState<IChip[]>([]);
	const [newDataEnricher, setNewDataEnricher] = useState<IChip[]>([]);
	const [isCorruptSearchIndex, setIsCorruptSearchIndex] =
		useState<boolean>(false);
	const [currentPersons, setCurrentPersons] = useState<IEventPerson[]>([]);

	const searchIndexScan = async (id: string) => {
		const promise = patchData({
			method: 'get',
			path: `/event/events/${id}/`,
		});

		const response = await promise;
		const error = response?.errorData?.response?.data;

		if (error?.code) {
			setIsCorruptSearchIndex(true);
		} else {
			setIsCorruptSearchIndex(false);
		}
	};

	const handleDeleteEvent = () => {
		fetchData({
			method: 'delete',
			path: `/search/events/${eventId}`,
			onSuccessMessage: 'Успешно удалено',
		})
			.then(() => setOpenModal(''))
			.then(() => navigate(`${ADMIN_EVENTS_ROUTE}`));
	};

	useEffect(() => {
		if (eventId) {
			searchIndexScan(eventId);
		}
	}, [eventId]);

	const fetchDataFunction = () => {
		fetchData({ path: `/search/events/${eventId}`, method: 'get' });
	};

	const handleSave = async () => {
		const diffObject = findObjectsDiffs(formData, eventData);
		const diffKeys = Object.keys(diffObject);
		// eslint-disable-next-line
		const diffData: Record<string, any> = {};

		if (diffKeys.length !== 0) {
			diffKeys.forEach((key) => {
				diffData[key] = formData[key as keyof IEventEdit];
			});
		}

		if (hasNewDescription && eventData.description !== newDescription) {
			diffData.description = newDescription;
		}

		if (eventData.category?.id !== newCategory.id)
			diffData.category_id = newCategory.id;

		if (
			!arraysAreEqual(
				eventData?.genres.map((genre) => genre.name),
				newGenres.map((genre) => genre.label),
			)
		) {
			diffData.genres = newGenres.map((genre) => genre.value);
		}

		if (eventData.label_top?.id !== newTopLabel.id && newTopLabel.id !== '')
			diffData.label_top_id = newTopLabel.id;

		if (
			eventData.label_bottom?.id !== newBottomLabel.id &&
			newBottomLabel.id !== ''
		)
			diffData.label_bottom_id = newBottomLabel.id;

		if (
			!arraysAreEqual(
				eventData?.countries,
				newCountries.map(({ value }) => value),
			)
		) {
			diffData.countries = newCountries.map(({ value }) => value);
		}

		if (eventData?.year !== newYear) {
			diffData.year = newYear === 0 ? null : newYear;
		}

		if (eventData?.is_enabled !== isNewEnabled)
			diffData.is_enabled = isNewEnabled;

		if (Object.keys(diffData).length > 0) {
			const editPromise = patchData({
				path: `/event/events/${eventId}/`,
				method: 'patch',
				body: replaceEmptyWithNull(diffData),
				onSuccessMessage: 'Изменения сохранены',
			});

			const response = await editPromise;
			const error = response?.errorData?.response?.data;

			if (error?.code) {
				setSnackbarContent({
					type: 'error',
					message: getErrorMessage(error, eventFieldNameMap),
				});
			} else {
				await fetchDataFunction();
			}
		}
	};

	const handleReset = async () => {
		const resetPromise = patchData({
			method: 'put',
			path: `/event/events/${eventId}/reset-changed-fields/`,
			onSuccessMessage: 'Изменения отменены',
		});

		const response = await resetPromise;
		const error = response?.errorData?.response?.data;

		if (error?.code) {
			setSnackbarContent({
				type: 'error',
				message: getErrorMessage(error, eventFieldNameMap),
			});
		} else {
			await fetchDataFunction();
		}
	};

	const onCoverSelect = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0] || null;

		handleFileSelection({
			minDimension: MIN_DIMENSION,
			aspectRatio: ASPECT_RATIO,
			file: file,
			setCropError: setCropError,
			setImageSrc: setImageSrc,
			setOpenModal: setOpenModal,
			setSnackbarContent: setSnackbarContent,
			setOriginal: setNewCoverOriginal,
		});
	};

	const handleImageDelete = (indexToRemove: number) => {
		const newSelectedImages = selectedImages.filter(
			(_, index) => index !== indexToRemove,
		);
		setSelectedImages(newSelectedImages);
	};

	const handleIsNewEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
		setIsNewEnabled(event.target.checked);
	};

	const onCategoryChange = (event: ChangeEvent<HTMLSelectElement>) => {
		setPrevCategory(newCategory);
		const { value } = event.target;
		if (!value) {
			setNewCategory({
				id: null,
				name: '',
			});
			return;
		}

		const newCategoryObj = allCategories.find(
			(element) => element.name === value,
		);

		if (newCategoryObj) {
			setNewCategory({
				id: newCategoryObj.id,
				name: newCategoryObj.name,
			});
		}

		setOpenModal('changeCategory');
	};

	const handleLabelSelectChange = (
		event: ChangeEvent<HTMLSelectElement>,
		type: string,
	) => {
		const { value } = event.target;
		switch (type) {
			case 'top':
				if (!value) {
					setNewTopLabel({
						id: null,
						name: '',
					});
				} else {
					const newTopLabelObj = allTopLabels.find(
						(element) => element.name === value,
					);
					if (newTopLabelObj) {
						setNewTopLabel({
							id: newTopLabelObj.id,
							name: newTopLabelObj.name,
						});
					}
				}
				break;
			case 'bottom':
				if (!value) {
					setNewBottomLabel({
						id: null,
						name: '',
					});
				} else {
					const newBottomLabelObj = allBottomLabels.find(
						(element) => element.name === value,
					);
					if (newBottomLabelObj) {
						setNewBottomLabel({
							id: newBottomLabelObj.id,
							name: newBottomLabelObj.name,
						});
					}
				}
				break;
			default:
				break;
		}
	};

	const handleTabChange = (tab: TabId) => {
		//если таб меняется, сбрасываем квери параметры, так как на каждом табе они должны быть независимы.
		if (tab !== selectedTab) {
			setSearchParams('');
			setSelectedTab(tab);
		}
	};
	useEffect(() => {
		sessionStorage.setItem('selectedEventTab', selectedTab);
	}, [selectedTab]);

	useEffect(() => {
		if (!topLabelOptionsLoading && topLabelOptionsData?.results) {
			setAllTopLabels(topLabelOptionsData.results);
		}
	}, [topLabelOptionsData?.results, topLabelOptionsLoading]);

	useEffect(() => {
		if (!bottomLabelOptionsLoading && bottomLabelOptionsData?.results) {
			setAllBottomLabels(bottomLabelOptionsData.results);
		}
	}, [bottomLabelOptionsData?.results, bottomLabelOptionsLoading]);

	useEffect(() => {
		if (!categoryOptionsLoading && categoryOptionsData?.results) {
			setAllCategories(categoryOptionsData.results);
		}
	}, [categoryOptionsData?.results, categoryOptionsLoading]);

	useEffect(() => {
		if (allCategories.length > 0) {
			const newCategoryOptions = allCategories.map((item) => ({
				label: item.name,
				value: item.name,
			}));
			setCategoryOptions(newCategoryOptions);
		}
	}, [allCategories]);

	useEffect(() => {
		if (allTopLabels.length > 0) {
			const newTopLabelOptions = allTopLabels.map((item) => ({
				label: item.name,
				value: item.name,
			}));
			setTopLabelOptions(newTopLabelOptions);
		}
	}, [allTopLabels]);

	useEffect(() => {
		if (allBottomLabels.length > 0) {
			const newBottomLabelOptions = allBottomLabels.map((item) => ({
				label: item.name,
				value: item.name,
			}));
			setBottomLabelOptions(newBottomLabelOptions);
		}
	}, [allBottomLabels]);

	useEffect(() => {
		fetchDataFunction();

		topLabelOptionsFetch({
			method: 'get',
			path: `/event/label/`,
			params: {
				is_active: true,
				type: 'event',
				page_size: PAGE_SIZE_LABEL,
			}
		});

		bottomLabelOptionsFetch({
			method: 'get',
			path: `/event/label/`,
			params: {
				is_active: true,
				type: 'price',
				page_size: PAGE_SIZE_LABEL,
			}
		});

		categoryOptionsFetch({
			method: 'get',
			path: `/event/categories/`,
			params: {
				is_active: true,
				page_size: PAGE_SIZE_CATEGORIES,
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (methodType === 'edit') {
			if (!loading && data) {
				setEventData(data);
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading]);

	useEffect(() => {
		if (eventData.preview_url_original) {
			handleImageUrl(
				MIN_DIMENSION,
				ASPECT_RATIO,
				eventData.preview_url_original,
				setCropError,
				setImageSrc,
			);
		}

		setNewDescription(eventData.description);
		setNewCover(eventData.preview_url);
		setNewCoverOriginal(eventData.preview_url_original);

		eventData.is_enabled && setIsNewEnabled(eventData.is_enabled);

		if (eventData.category) {
			setNewCategory({
				id: eventData.category.id,
				name: eventData.category.name,
			});
		}

		if (eventData.label_top) {
			setNewTopLabel({
				id: eventData.label_top.id,
				name: eventData.label_top.name,
			});
		}

		if (eventData.label_bottom) {
			setNewBottomLabel({
				id: eventData.label_bottom.id,
				name: eventData.label_bottom.name,
			});
		}

		setSelectedImages([]);
		setSelectedImages((prevImages) => {
			if (Array.isArray(eventData.image_urls)) {
				return [...prevImages, ...eventData.image_urls];
			} else if (typeof eventData.image_urls === 'string') {
				return [...prevImages, eventData.image_urls];
			} else {
				return prevImages;
			}
		});

		if (eventData.countries) {
			setNewCountries(
				eventData.countries.map((country) => {
					return { label: country, value: country };
				}),
			);
		}

		if (eventData.genres) {
			setNewGenres(
				eventData.genres.map(({ id, name }) => {
					return { label: name, value: id };
				}),
			);
		}

		setNewYear(eventData.year);

		if (eventData.data_enricher) {
			setNewDataEnricher(
				eventData.data_enricher.map((e) => {
					return { label: e, value: e };
				}),
			);
		}

		setCurrentPersons(eventData.persons);
	}, [eventData]);

	useEffect(() => {
		if (
			!arraysAreEqual(
				Array.isArray(eventData.image_urls)
					? eventData.image_urls
					: [eventData.image_urls],
				selectedImages,
			)
		) {
			const editedData = { image_urls: selectedImages };

			patchData({
				path: `/event/events/${eventId}/`,
				method: 'patch',
				body: editedData,
				onSuccessMessage: 'Изображения обновлены',
			});
		}
	}, [selectedImages]);

	useEffect(() => {
		newDescription === '<p><br></p>' && setNewDescription('');
	}, [newDescription]);

	useEffect(() => {
		const updateCover = async () => {
			if (eventData.preview_url !== newCover) {
				const diffData: Record<string, string> = {};
				diffData.preview_url = newCover;

				if (
					newCoverOriginal !== '' &&
					eventData.preview_url_original !== newCoverOriginal
				) {
					diffData.preview_url_original = newCoverOriginal;
				}

				if (
					newCoverOriginal === '' &&
					eventData.preview_url_original !== newCoverOriginal
				) {
					diffData.preview_url_original = '';
				}

				try {
					await patchData({
						path: `/event/events/${eventId}/`,
						method: 'patch',
						body: diffData,
						onSuccessMessage: 'Обложка обновлена',
					});

					navigate(0);
				} catch (error) {
					setSnackbarContent({
						type: 'error',
						message: 'Ошибка при обновлении обложки',
					});
				}
			}
		};

		updateCover();
	}, [newCover]);

	return (
		<>
			{loading ? (
				<Div style={{ width: '100%', height: '400px' }}>
					<Spinner size="medium" className="spinner" />
				</Div>
			) : (
				<>
					<PanelTitle arrowColor="#99A2AD">
						<Title className="text-color-black">
							{methodType === 'edit'
								? `Детали мероприятия`
								: 'Добавить мероприятие'}
						</Title>
						{methodType === 'edit' && (
							<Title className="inline text-color-steel-gray-500">
								{eventData.name}
							</Title>
						)}
					</PanelTitle>
					<View activePanel="panel" className="vkui-tabs">
						<Panel id="panel">
							<PanelHeader className="relative">
								<Tabs layoutFillMode={'shrinked'} className="flex pl-5">
									{tabs.map((tab) => (
										<TabsItem
											key={tab.id}
											selected={selectedTab === tab.id}
											onClick={() => {
												handleTabChange(tab.id);
											}}
											id="tab-info"
										>
											{tab.title}
										</TabsItem>
									))}
								</Tabs>
								{changed_from_admin_at && (
									<span
										className="absolute right-4 top-4 flex justify-center gap-1"
										style={{ color: '#99A2AD' }}
									>
										<Icon20UserPenOutline fill="#99A2AD" width={18} />
										<span> Изменено админом:</span>
										{parseDateFrom({
											date: changed_from_admin_at,
											format: 'daytime',
											utc: false,
										})}
									</span>
								)}
							</PanelHeader>
							{selectedTab === TabId.info && (
								<Group
									id="tab-content-info"
									role="tabpanel"
								>
									{isCorruptSearchIndex && (
										<Group mode="plain">
											<CardGrid size="l">
												<Card mode="shadow">
													<Header mode="secondary">
														мероприятие было удалено
													</Header>
													<Div>
														<Button
															mode="secondary"
															size="l"
															appearance="negative"
															onClick={() => setOpenModal('delete')}
														>
															Удалить из поиска
														</Button>
													</Div>
												</Card>
											</CardGrid>
										</Group>
									)}
									<EventFormItem
										className="vkui-input"
										top="ID"
										name="id"
										value={id || ''}
										onChange={handleChange}
										placeholder="Введите ID"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly
										changed_from_admin={changed_from_admin}
									/>
									<EventFormItem
										className="vkui-input"
										top="ID мероприятия у Поставщика"
										name="provider_id"
										value={provider_id ? String(provider_id) : ''}
										onChange={handleChange}
										placeholder="ID поставщика не указан"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly
										changed_from_admin={changed_from_admin}
									/>
									<EventFormItem
										className="vkui-input"
										top="Поставщик"
										name="provider"
										value={provider || ''}
										onChange={handleChange}
										placeholder="Поставщик не указан"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly
										changed_from_admin={changed_from_admin}
									/>
									<EventFormItem
										className="vkui-input"
										top="Владелец данных"
										name="source_owner"
										value={source_owner || ''}
										onChange={handleChange}
										placeholder="Владелец не указан"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly
										changed_from_admin={changed_from_admin}
									/>
									<FormItem
										htmlFor="data_enricher"
										top="Обогащение данных"
										bottom={
											changed_from_admin?.includes('data_enricher') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<ChipsInput
											id="data_enricher"
											placeholder="Источники не указаны"
											value={newDataEnricher || []}
											disabled
										/>
									</FormItem>
									<EventFormItem
										className="vkui-input"
										top="Рейтинг"
										name="rating"
										value={`${rating}`}
										onChange={handleChange}
										placeholder="Введите ID"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly
										changed_from_admin={changed_from_admin}
									/>
									<EventFormItem
										className="vkui-input"
										top="Название"
										name="name"
										value={name || ''}
										onChange={handleChange}
										placeholder="Введите название"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly={userRole === 'support'}
										changed_from_admin={changed_from_admin}
									/>
									<FormItem
										top="Описание"
										bottom={
											changed_from_admin?.includes('description') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<ReactQuill
											className="text-editor"
											value={newDescription}
											onChange={(value) => setNewDescription(value)}
											onChangeSelection={() => setHasNewDescription(true)}
											modules={textEditorModules}
											formats={textEditorFormats}
											readOnly={userRole === 'support'}
										/>
									</FormItem>
									<FormItem
										top="Тип"
										htmlFor="category"
										bottom={
											changed_from_admin?.includes('category_id') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<Select
											className="vkui-select"
											id="category"
											name="category"
											placeholder="Выберите тип"
											value={newCategory.name as string}
											onChange={onCategoryChange}
											options={categoryOptions || []}
											disabled={userRole === 'support'}
										/>
									</FormItem>
									<FormItem
										htmlFor="genres"
										top="Жанр"
										bottom={
											changed_from_admin?.includes('genres') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<SelectGenres
											categoryId={newCategory?.id}
											newGenres={newGenres}
											setNewGenres={setNewGenres}
										/>
									</FormItem>
									<EventFormItem
										className="vkui-input"
										top="Возрастная категория"
										name="age_category"
										value={age_category || ''}
										onChange={handleChange}
										placeholder="Укажите возрастную категорию"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly={userRole === 'support'}
										changed_from_admin={changed_from_admin}
									/>
									<FormItem
										top="Лейбл мероприятия"
										bottom={
											changed_from_admin?.includes('label_top_id') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<Select
											name="label_top"
											className="vkui-select"
											placeholder="Укажите лейбл"
											allowClearButton
											value={newTopLabel.name as string}
											onChange={(event) =>
												handleLabelSelectChange(event, 'top')
											}
											options={topLabelOptions || []}
											disabled={userRole === 'support'}
										/>
									</FormItem>
									<FormItem
										top="Лейбл цены"
										bottom={
											changed_from_admin?.includes('label_bottom_id') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<Select
											name="label_bottom"
											className="vkui-select"
											placeholder="Укажите лейбл"
											allowClearButton
											value={newBottomLabel.name as string}
											onChange={(event) =>
												handleLabelSelectChange(event, 'bottom')
											}
											options={bottomLabelOptions || []}
											disabled={userRole === 'support'}
										/>
									</FormItem>
									<EventFormItem
										className="vkui-input"
										top="Ссылка на событие ВК"
										name="vk_url"
										value={vk_url || ''}
										onChange={handleChange}
										placeholder="Укажите ссылку на событие"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly={userRole === 'support'}
										changed_from_admin={changed_from_admin}
									/>
									<EventFormItem
										className="vkui-input"
										top="Организатор"
										name="organizer"
										value={organizer || ''}
										onChange={handleChange}
										placeholder="Организатор не указан"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly
										changed_from_admin={changed_from_admin}
									/>
									<FormItem
										htmlFor="countries"
										top="Страна"
										bottom={
											changed_from_admin?.includes('countries') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<ChipsInput
											id="countries"
											placeholder="Страна не указана"
											value={newCountries || []}
											onChange={setNewCountries}
											disabled={userRole === 'support'}
										/>
									</FormItem>
									<FormItem
										className="vkui-input"
										top="Год создания"
										bottom={
											changed_from_admin?.includes('year') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<NumberInput
											value={newYear}
											onChange={setNewYear}
											name="year"
											placeholder="Год не указан"
											maxLength={4}
											readOnly={userRole === 'support'}
										/>
									</FormItem>
									<EventFormItem
										className="vkui-input"
										top="Изменено админом"
										name="changed_from_admin"
										value={changed_from_admin.length > 0 ? 'Да' : 'Нет'}
										onChange={handleChange}
										placeholder="Изменено админом"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly
										changed_from_admin={changed_from_admin}
									/>
									<EventFormItem
										className="vkui-input"
										top="Пушкинская карта"
										name="is_pushkin_card"
										value={is_pushkin_card ? 'Да' : 'Нет'}
										onChange={handleChange}
										placeholder="Пушкинская карта"
										isRequired={true}
										isError={isError}
										errorItems={errorItems}
										readOnly
										changed_from_admin={changed_from_admin}
									/>
									<FormItem
										className="mb-2 ml-3 mt-3 w-44 py-0"
										bottom={
											changed_from_admin?.includes('is_enabled') ? (
												<div className="flex items-center gap-1">
													<Icon20UserPenOutline fill="#99A2AD" width={18} />
													<span style={{ color: '#99A2AD' }}>
														Изменено админом
													</span>
												</div>
											) : (
												''
											)
										}
									>
										<Checkbox
											checked={isNewEnabled}
											onChange={handleIsNewEnabledChange}
											disabled={userRole === 'support'}
										>
											Активно
										</Checkbox>
									</FormItem>
									<Div>
										<Button
											mode="secondary"
											size="l"
											appearance="accent"
											href={`${ADMIN_REVIEWS_ROUTE}?event_id="${id}"&page="0"`}
											target="_blank"
										>
											Посмотреть отзывы
										</Button>
									</Div>
									{userRole !== 'support' && (
										<Div>
											<ButtonGroup align="right" stretched>
												<Button
													className="vkui-edit-button-secondary"
													mode="primary"
													size="l"
													appearance="accent"
													onClick={handleReset}
													disabled={changed_from_admin.length === 0}
												>
													Сбросить изменения
												</Button>
												<Button
													className="vkui-edit-button-primary"
													mode="primary"
													size="l"
													appearance="accent"
													onClick={handleSave}
												>
													Сохранить
												</Button>
											</ButtonGroup>
										</Div>
									)}
								</Group>
							)}
							{selectedTab === TabId.sessions && (
								<Group
									id="tab-content-sessions"
									role="tabpanel"
								>
									<EventSeances eventType={newCategory} />
								</Group>
							)}
							{selectedTab === TabId.media && (
								<Group
									id="tab-content-media"
									role="tabpanel"
								>
									<Div className="pb-8">
										<Div className="m-0 flex justify-between gap-2 px-2 py-0">
											<div className="flex items-center">
												<Header mode="primary">Обложка</Header>
												{changed_from_admin?.includes('preview_url') ? (
													<div className="mt-2 flex items-center gap-1">
														<Icon20UserPenOutline fill="#99A2AD" width={18} />
														<span
															style={{ color: '#99A2AD', fontSize: '13px' }}
														>
															Изменено админом
														</span>
													</div>
												) : (
													''
												)}
											</div>
											{userRole !== 'support' && (
												<div className="flex flex-row">
													{imageSrc && (
														<FormItem className="-mr-4">
															<Button
																className="bg-color-blue-button"
																before={
																	<Icon20CropOutline
																		width={18}
																		className="ml-0.5"
																	/>
																}
																mode="secondary"
																size="l"
																appearance="accent"
																accept="image/*"
																onClick={() => setOpenModal('crop')}
															>
																Изменить обложку
															</Button>
														</FormItem>
													)}
													<FormItem>
														<File
															before={
																<Icon20PicturePlusOutline
																	width={18}
																	className="ml-0.5"
																/>
															}
															mode="secondary"
															size="l"
															appearance="accent"
															accept="image/*"
															onChange={onCoverSelect}
														>
															Новая обложка
														</File>
													</FormItem>
												</div>
											)}
										</Div>
										{newCover ? (
											<Div className="m-0 flex gap-2 px-5 py-0">
												<Image
													className="group m-0 p-0"
													size={96}
													src={newCover}
													onClick={() => setOpenModal('coverPreview')}
												>
													{userRole !== 'support' && (
														<Div className="m-2 p-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
															<Image.Badge
																background={'stroke'}
																className="bg-white"
															>
																<Icon20DeleteOutline
																	color={'#99A2AD'}
																	onClick={(event) => {
																		event.stopPropagation();
																		setOpenModal('deleteCover');
																	}}
																	className="cursor-pointer"
																/>
															</Image.Badge>
														</Div>
													)}
												</Image>
											</Div>
										) : (
											<Div>
												<Placeholder
													className="m-0 p-0"
													icon={<Icon24Attachments />}
													header={'Обложка отсутствует'}
												>
													Выберите обложку
												</Placeholder>
											</Div>
										)}
										{(!eventData.category ||
											eventData.category.name === 'Кино') && (
												<Div className="m-0 mt-4 px-2 py-0">
													<div className="flex items-center">
														<Header mode="primary">Трейлер</Header>
														{changed_from_admin?.includes('trailer') ? (
															<div className="mt-2 flex items-center gap-1">
																<Icon20UserPenOutline fill="#99A2AD" width={18} />
																<span
																	style={{ color: '#99A2AD', fontSize: '13px' }}
																>
																	Изменено админом
																</span>
															</div>
														) : (
															''
														)}
													</div>
													{trailer ? (
														<Div
															className="relative flex w-fit items-center justify-center"
															onClick={() =>
																window.open(`${trailer.hd}`, '_blank')
															}
														>
															<Image
																className="m-0 cursor-pointer p-0"
																size={96}
																src={trailer?.thumbnail || ''}
															/>
															<Icon28PlayCircleFillGray className="absolute cursor-pointer" />
														</Div>
													) : (
														<Div>
															<Placeholder
																className="m-0 p-0"
																icon={<Icon24Attachments />}
																header={'Трейлер не найден'}
															>
																Возможно, он появится позже
															</Placeholder>
														</Div>
													)}
													<div className="flex items-center">
														<Header mode="primary" className="m-0 my-4 px-3 py-0">
															Постер
														</Header>
														{changed_from_admin?.includes('poster') ? (
															<div className="mt-2 flex items-center gap-1">
																<Icon20UserPenOutline fill="#99A2AD" width={18} />
																<span
																	style={{ color: '#99A2AD', fontSize: '13px' }}
																>
																	Изменено админом
																</span>
															</div>
														) : (
															''
														)}
													</div>

													{poster ? (
														<Div className="m-0 flex gap-2 px-3 py-0">
															<Image
																className="group m-0 p-0"
																size={96}
																src={poster}
																onClick={() => setOpenModal('posterPreview')}
															></Image>
														</Div>
													) : (
														<Div>
															<Placeholder
																className="m-0 p-0"
																icon={<Icon24Attachments />}
																header={'Постер не найден'}
															>
																Возможно, он появится позже
															</Placeholder>
														</Div>
													)}
												</Div>
											)}
										<Div className="m-0 mt-4 flex justify-between gap-2 px-2 py-0">
											<div className="flex items-center">
												<Header mode="primary">Изображения</Header>
												{changed_from_admin?.includes('image_urls') ? (
													<div className="mt-1 flex items-center gap-1">
														<Icon20UserPenOutline fill="#99A2AD" width={18} />
														<span
															style={{ color: '#99A2AD', fontSize: '13px' }}
														>
															Изменено админом
														</span>
													</div>
												) : (
													''
												)}
											</div>
											{userRole !== 'support' && (
												<FormItem className="mb-4">
													<File
														before={
															<Icon20PictureOnSquareOutline
																width={20}
																className="ml-0.5"
															/>
														}
														mode="secondary"
														size="l"
														appearance="accent"
														onChange={(e) =>
															handleImageChange(e, {
																setSelectedImages,
																setSnackbarContent,
															})
														}
														accept="image/*"
													>
														Добавить изображение
													</File>
												</FormItem>
											)}
										</Div>
										{selectedImages.length > 0 ? (
											<Div className="m-0 flex flex-wrap gap-2 px-5 py-0">
												{selectedImages.map((img, index) => {
													return (
														<Div
															key={index}
															className="m-0 flex flex-col items-center p-0"
														>
															<Image
																className="group m-0 p-0"
																size={96}
																src={img}
																onClick={() => {
																	setOpenModal('imageGallery');
																	setDisplayedImageIndex(index);
																}}
															>
																{userRole !== 'support' && (
																	<Div className="m-2 p-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
																		<Image.Badge
																			background={'stroke'}
																			className="bg-white"
																		>
																			<Icon20DeleteOutline
																				color={'#99A2AD'}
																				onClick={(event) => {
																					event.stopPropagation();
																					setOpenModal('deleteMedia');
																					setDeleteIndex(index);
																				}}
																				className="cursor-pointer"
																			/>
																		</Image.Badge>
																	</Div>
																)}
															</Image>
															<input
																type="text"
																disabled={userRole === 'support'}
																onInput={(event) =>
																	handleOrderInput(event, index, {
																		selectedImages,
																		setSelectedImages,
																	})
																}
																className="mb-2 mt-2 max-h-8 w-8 rounded-md border-1 border-none bg-color-card-grey py-px text-center text-color-steel-gray-500 focus:outline-none focus:ring-2 focus:ring-color-nav-items-blue"
																value={index + 1}
															/>
														</Div>
													);
												})}
											</Div>
										) : (
											<Div>
												<Placeholder
													className="m-0 p-0"
													icon={<Icon24Attachments />}
													header={'Медиафайлы не найдены'}
												>
													Добавьте изображение
												</Placeholder>
											</Div>
										)}
									</Div>
								</Group>
							)}
							{selectedTab === TabId.persons && (
								<Group
									id="tab-content-info"
									role="tabpanel"
								>
									<EventPersons
										currentPersons={currentPersons}
										eventId={eventId}
									/>
								</Group>
							)}
							{selectedTab === TabId.links && (
								<Group
									id="tab-content-links"
									role="tabpanel"
								>
									<EventLinks />
								</Group>
							)}
						</Panel>
					</View>
				</>
			)}
			<ModalRoot activeModal={openModal}>
				<ModalPage
					className="fixed"
					id="crop"
					onClose={() => setOpenModal('')}
					settlingHeight={100}
					height={'70%'}
					size={'l'}
				>
					{cropError && <p className="text-red-400">{cropError}</p>}
					<ImageCropper
						minDimension={MIN_DIMENSION}
						aspectRatio={ASPECT_RATIO}
						imageSrc={imageSrc}
						setOpenModal={setOpenModal}
						setState={setNewCover}
					/>
				</ModalPage>
				<ModalPage
					id="coverPreview"
					onClose={() => setOpenModal('')}
					settlingHeight={100}
					className="fixed flex max-h-fit flex-col items-center justify-center"
					size={1200}
					dynamicContentHeight={true}
				>
					<Div className="relative flex max-h-fit flex-col items-center justify-center">
						<img
							className="max-h-[640px] rounded-3xl object-contain p-4"
							src={newCover}
							alt=""
						/>
					</Div>
				</ModalPage>
				<ModalPage
					id="posterPreview"
					onClose={() => setOpenModal('')}
					settlingHeight={100}
					className="fixed flex max-h-fit flex-col items-center justify-center"
					size={1200}
					dynamicContentHeight={true}
				>
					<Div className="relative flex max-h-fit flex-col items-center justify-center">
						<img
							className="max-h-[640px] rounded-3xl object-contain p-4"
							src={poster}
							alt=""
						/>
					</Div>
				</ModalPage>
				<ModalPage
					className="fixed"
					id="imageGallery"
					onClose={() => setOpenModal('')}
					settlingHeight={100}
					height={'70%'}
					size={'l'}
				>
					<ImageGallery
						selectedImages={selectedImages}
						displayedImageIndex={displayedImageIndex}
						setDisplayedImageIndex={setDisplayedImageIndex}
					/>
				</ModalPage>
				<ModalCard
					className="fixed"
					id="deleteCover"
					onClose={() => setOpenModal('')}
				>
					<Div className="flex flex-col gap-2 p-0">
						<Title className="mb-2 text-color-black" level="2">
							Удаление обложки
						</Title>
						<Text className="mb-6 text-color-gray-600">
							Вы действительно хотите удалить обложку?
						</Text>
						<ButtonGroup className="pt-3" align="right" stretched>
							<Button
								className="vkui-edit-button-secondary"
								mode="secondary"
								size="m"
								onClick={() => setOpenModal('')}
							>
								Назад
							</Button>
							<Button
								mode="primary"
								className="vkui-edit-button-error"
								size="m"
								onClick={() => {
									setNewCover('');
									setNewCoverOriginal('');
									setOpenModal('');
								}}
							>
								Удалить
							</Button>
						</ButtonGroup>
					</Div>
				</ModalCard>
				<ModalCard
					className="fixed"
					id="deleteMedia"
					onClose={() => {
						setOpenModal('');
						setDeleteIndex(null);
					}}
				>
					<Div className="flex flex-col gap-2 p-0">
						<Title className="mb-2 text-color-black" level="2">
							Удаление изображения
						</Title>
						<Text className="mb-6 text-color-gray-600">
							Вы действительно хотите удалить изображение?
						</Text>
						<ButtonGroup className="pt-3" align="right" stretched>
							<Button
								className="vkui-edit-button-secondary"
								mode="secondary"
								size="m"
								onClick={() => {
									setOpenModal('');
									setDeleteIndex(null);
								}}
							>
								Назад
							</Button>
							<Button
								mode="primary"
								className="vkui-edit-button-error"
								size="m"
								onClick={() => {
									if (deleteIndex !== null) {
										handleImageDelete(deleteIndex);
										setOpenModal('');
										setDeleteIndex(null);
									}
								}}
							>
								Удалить
							</Button>
						</ButtonGroup>
					</Div>
				</ModalCard>
				<ModalCard
					className="fixed"
					id="changeCategory"
					onClose={() => {
						setNewCategory(prevCategory);
						setOpenModal('');
					}}
				>
					<Div className="flex flex-col gap-2 p-0">
						<Title className="mb-2 text-color-black" level="2">
							Изменение типа
						</Title>
						<Text className="mb-6 text-color-gray-600">
							При смене типа будут сброшены выбранные жанры
						</Text>
						<ButtonGroup className="pt-3" align="right" stretched>
							<Button
								className="vkui-edit-button-secondary"
								mode="secondary"
								size="m"
								onClick={() => {
									setNewCategory(prevCategory);
									setOpenModal('');
								}}
							>
								Отменить
							</Button>
							<Button
								mode="primary"
								className="vkui-edit-button-error"
								size="m"
								onClick={() => {
									setOpenModal('');
								}}
							>
								Продолжить
							</Button>
						</ButtonGroup>
					</Div>
				</ModalCard>
				<ModalCard id="delete" onClose={() => setOpenModal('')}>
					<Div className="flex flex-col gap-2 p-0">
						<Title className="text-color-black" level="2">
							Удаление мероприятия
						</Title>
						<Text className="text-color-gray-600">
							Данное мероприятие будет удалено из поиска
						</Text>
						<ButtonGroup className="pt-3" align="right" stretched>
							<Button
								className="vkui-edit-button-secondary"
								mode="secondary"
								size="m"
								onClick={() => setOpenModal('')}
							>
								Отмена
							</Button>
							<Button
								className="vkui-edit-button-primary"
								mode="primary"
								size="m"
								onClick={() => handleDeleteEvent()}
							>
								Удалить
							</Button>
						</ButtonGroup>
					</Div>
				</ModalCard>
			</ModalRoot>
		</>
	);
};

export default EventsEdit;
