import { TabId } from './types.persons';
import { IPersonEdit } from "./types.persons";

export const personHeaders = [
	{
		order: 1,
		label: 'ID',
		value: 'id',
	},
	{
		order: 2,
		label: 'ФИО',
		value: 'full_name',
	},
	{
		order: 3,
		label: 'Аватар',
		value: 'preview_url',
	},
	{
		order: 4,
		label: 'Активность',
		value: 'is_enabled',
	},
	{
		order: 5,
		label: 'Сообщества',
		value: 'communities',
	},
];

export const personFilters = {
	q: '',
	has_preview_url: '',
	is_enabled: '',
	community: '',
};

export const personBlank: IPersonEdit = {
	id: '',
	slug: '',
	full_name: '',
	birthday: '',
	description: '',
	vk_page: '',
	preview_url: '',
	preview_url_original: '',
	image_urls: [],
	is_enabled: false,
	communities: [],
	meta: null,
};

export const personFieldNameMap = {
  full_name: "ФИО",
  id: "ID",
  slug: "Ссылка",
  newCover: "Аватар",
  vk_page: "Ссылка на странице ВК",
  newCommunities: "Сообщества",
  newBirthday: "Дата рождения",
  newDescription: "Описание",
  isNewEnabled: "Активна",
};

export const tabs = [
	{ title: 'Информация', id: 'info' },
	{ title: 'SEO Мета', id: 'seo' },
	{ title: 'Мероприятия', id: 'events' },
	{ title: 'Места проведения', id: 'places' },
	{ title: 'Персоны', id: 'persons' },
] satisfies { title: string; id: TabId }[];

/**
 * @todo добавить все айдишки модалок
 */
export const MODAL_ID = {
	coverPreview: 'coverPreview',
	addEvents: 'addEvents',
	addPersons: 'addPersons',
	addPlaces: 'addPlaces',
};