import React from 'react';

export const useStateWithSessionStorage = <T extends string>(
  key: string,
  defaultValue: T,
): [T, (newState: T) => void] => {
  const [state, _setState] = React.useState<T>(
    () => (sessionStorage.getItem(key) as T) ?? defaultValue,
  );

  const setState = React.useCallback(
    (newState: T) => {
      sessionStorage.setItem(key, newState);
      _setState(newState);
    },
    [key, _setState],
  );

  return [state, setState];
};
