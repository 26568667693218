import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Div,
  Group,
  FormItem,
  Button,
  DateInput,
  LocaleProvider,
  Image,
  File,
  Checkbox,
} from '@vkontakte/vkui';
import {
  Icon20DeleteOutline,
  Icon20CropOutline,
  Icon20PicturePlusOutline,
  Icon20StorySlashOutline,
  Icon20AddCircle,
  Icon24LinkCircleFilled,
  Icon24RemoveCircle,
} from '@vkontakte/icons';
import EventFormItem from 'components/common/EventFormItem';
import { FormItemChangeHandler } from 'lib/hooks/useFormItems';
import clsx from 'clsx';
import { IChip } from 'lib/types';
import { NumberInput } from 'components/common/NumberInput';
import ReactQuill from 'react-quill';
import { textEditorFormats, textEditorModules } from 'lib/utils/textEditor';
import LinkFromApp from 'components/common/LinkFromApp';

type Props = {
  id: string;
  slug: string;
  methodType: string | undefined;
  newCover: string;
  isError: boolean;
  errorItems: string[];
  imageSrc: string;
  fullName: string; // full_name
  handleChange: FormItemChangeHandler;
  onCoverClick: VoidFunction; // () => openModal('coverPreview')
  onCoverDelete: VoidFunction; // () => openModal('deleteCover')
  onCropClick: VoidFunction; // () => openModal('crop')
  onCoverSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddCommunity: VoidFunction; // handleAddCommunity
  onDeleteCommunity: (index: number) => void; // handleDeleteCommunity
  newBirthday: Date | undefined;
  vkPage: string; // vk_page
  setNewBirthday: React.Dispatch<React.SetStateAction<Date | undefined>>;
  isModalOpened: boolean;
  newCommunities: IChip[];
  onChangeCommunity: (value: string, index: number) => void; // handleChangeCommunity
  setNewDescription: React.Dispatch<React.SetStateAction<string>>;
  setHasNewDescription: React.Dispatch<React.SetStateAction<boolean>>;
  newDescription: string;
  isNewEnabled: boolean;
  handleIsNewEnabledChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  userRole: string | null;
  footerFormButton: React.ReactNode;
};

export const PersonsInfo = ({
  id,
  slug,
  methodType,
  newCover,
  isError,
  errorItems,
  imageSrc,
  fullName,
  onCoverClick,
  onCoverDelete,
  onCropClick,
  onCoverSelect,
  handleChange,
  onAddCommunity,
  onDeleteCommunity,
  newBirthday,
  setNewBirthday,
  isModalOpened,
  vkPage,
  newCommunities,
  onChangeCommunity,
  setNewDescription,
  setHasNewDescription,
  newDescription,
  isNewEnabled,
  handleIsNewEnabledChange,
  userRole,
  footerFormButton,
}: Props): React.ReactElement => {
  const handleCoverDelete = React.useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      event.stopPropagation();
      onCoverDelete();
    },
    [onCoverDelete],
  );

  return (
    <Group className="custom-scrollbar">
      {methodType === 'edit' && (
        <>
          <FormItem top="Ссылка на ВЕБ">
            <LinkFromApp obj="person" slugOrId={slug} app="web" />
          </FormItem>
          <FormItem top="Ссылка на миниапп">
            <LinkFromApp obj="person" slugOrId={id} app="miniapp" />
          </FormItem>
          <EventFormItem
            className="vkui-input"
            top="ID"
            name="id"
            value={id || ''}
            onChange={handleChange}
            placeholder="Укажите ID"
            isRequired={true}
            isError={isError}
            errorItems={errorItems}
            readOnly
          />
        </>
      )}
      <FormItem top="Аватар" className="w-72">
        <div className="py-4">
          {newCover ? (
            <Image
              className="group m-0 p-0"
              size={96}
              src={newCover}
              onClick={onCoverClick}
            >
              <Div className="m-2 p-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                <Image.Badge background={'stroke'} className="bg-white">
                  <Icon20DeleteOutline
                    color={'#99A2AD'}
                    onClick={handleCoverDelete}
                    className="cursor-pointer"
                  />
                </Image.Badge>
              </Div>
            </Image>
          ) : (
            <Image className="group m-0 p-0" size={96}>
              <Icon20StorySlashOutline />
            </Image>
          )}
        </div>
        <div className="flex flex-row gap-3">
          {imageSrc && (
            <Button
              className="bg-color-blue-button"
              before={<Icon20CropOutline width={18} className="ml-0.5" />}
              mode="secondary"
              size="l"
              appearance="accent"
              accept="image/*"
              onClick={onCropClick}
            >
              Изменить
            </Button>
          )}

          <File
            before={<Icon20PicturePlusOutline width={18} className="ml-0.5" />}
            mode="secondary"
            size="l"
            appearance="accent"
            accept="image/*"
            onChange={onCoverSelect}
          >
            Новый
          </File>
        </div>
      </FormItem>
      <EventFormItem
        className="vkui-input"
        top="ФИО"
        name="full_name"
        value={fullName}
        onChange={handleChange}
        placeholder="Укажите ФИО"
        isRequired={true}
        isError={isError}
        errorItems={errorItems}
      />
      <FormItem top="Дата рождения" className="w-72">
        <LocaleProvider value={'ru'}>
          <DateInput
            value={newBirthday}
            onChange={setNewBirthday}
            closeOnChange={true}
            className={clsx({
              'z-10': isModalOpened,
            })}
          />
        </LocaleProvider>
      </FormItem>
      <EventFormItem
        className="vkui-input"
        top="Ссылка на странице ВК"
        name="vk_page"
        value={vkPage || ''}
        onChange={handleChange}
        placeholder="Укажите ссылку"
        isRequired={true}
        isError={isError}
        errorItems={errorItems}
      />
      <FormItem className="vkui-input" top="Сообщества">
        <div className="mt-2 flex flex-col gap-2">
          {newCommunities.map(({ label }, index) => (
            <NumberInput
              key={index}
              className="vkui-input group w-72"
              value={Number(label) || null}
              onChange={(value: number | null) => {
                onChangeCommunity(value?.toString() || '', index);
              }}
              name="priority"
              placeholder="Укажите id сообщества"
              maxLength={15}
              after={
                <>
                  <Icon24LinkCircleFilled
                    className="absolute -right-9 top-1/2 -translate-y-1/2 transform cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                    width={24}
                    color={'#5181B8'}
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_COMMUNITY_BASE_URL + `${label}`,
                        '_blank',
                      )
                    }
                  />
                  <Icon24RemoveCircle
                    width={22}
                    color={'#e64646'}
                    className="absolute -right-16 top-1/2 -translate-y-1/2 transform cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                    onClick={() => onDeleteCommunity(index)}
                  />
                </>
              }
            />
          ))}
        </div>
        <Button
          className="mt-2"
          mode="secondary"
          size="m"
          appearance="accent"
          onClick={onAddCommunity}
          before={<Icon20AddCircle width={19} className="ml-0.5" />}
        >
          Добавить cообщество
        </Button>
      </FormItem>
      <FormItem top="Описание">
        <ReactQuill
          className="text-editor"
          value={newDescription}
          onChange={(value) => setNewDescription(value)}
          onChangeSelection={() => setHasNewDescription(true)}
          modules={textEditorModules}
          formats={textEditorFormats}
        />
      </FormItem>
      {methodType === 'edit' && (
        <FormItem className="mb-2 ml-3 mt-3 w-32 py-0">
          <Checkbox
            checked={isNewEnabled}
            onChange={handleIsNewEnabledChange}
            disabled={userRole === 'support'}
          >
            Активна
          </Checkbox>
        </FormItem>
      )}
      {footerFormButton}
    </Group>
  );
};
