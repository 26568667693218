import * as React from 'react';
import InputSearch from 'components/common/InputSearch';
import {
  Button,
  ButtonGroup,
  Card,
  Cell,
  Div,
  FormItem,
} from '@vkontakte/vkui';
import useDebounce from 'lib/hooks/useDebounce';
import useFetch from 'lib/hooks/useFetch';
import { ApiResponse } from 'lib/api/api';
import { IEventPerson } from 'components/Events/types.events';
import { ENDPOINTS } from 'lib/endpoints';
import { Popper } from '@vkontakte/vkui/dist/components/Popper/Popper';
import { SearchPersonItem } from './SearchPersonItem';
import { SelectedPersonItem } from './SelectedPersonItem';
import { SelectedType } from './types';
import { useClickOutside } from 'lib/hooks/useClickOutside';
import { useFlag } from 'lib/hooks/useFlag';
import { useEditList } from 'lib/hooks/useEditList';
import { useSnackbar } from 'lib/hooks/useSnackbar';

const PAGE_SIZE = 50;

type Props = {
  currentPersons: IEventPerson[];
  applying?: boolean;
  onApply?: (personIds: string[]) => void;
};

/**
 * Компонент для поиска и редактирования списка персон
 * @todo infinite scroll для найденных персон
 */
export const PersonSelection = ({
  currentPersons,
  applying,
  onApply,
}: Props): React.ReactElement => {
  const [inputValue, setInputValue] = React.useState('');

  const {
    data: personsData,
    loading: loadingPersons,
    fetchData,
    resetData,
  } = useFetch<ApiResponse<IEventPerson[]>>();

  const {
    oldEntities: oldPersons,
    newEntities: newPersons,
    addNew: addPerson,
    removeNew,
    removeOld,
  } = useEditList<IEventPerson>({ currentEntities: currentPersons });

  const {
    flag: isPopperShown,
    setTrue: showPopper,
    setFalse: closePopper,
  } = useFlag();

  const { setSnackbarContent } = useSnackbar();

  const oldPersonsIds = React.useMemo(
    () => oldPersons.map(({ id }) => id),
    [oldPersons],
  );

  const newPersonsIds = React.useMemo(
    () => newPersons.map(({ id }) => id),
    [newPersons],
  );

  const personsIds = React.useMemo(
    () => [...newPersonsIds, ...oldPersonsIds],
    [oldPersonsIds, newPersonsIds],
  );

  const debouncedQuery = useDebounce(inputValue);
  const inputRef = React.useRef(null);
  const searchResultsRef = React.useRef(null);

  const fetchPersons = React.useCallback(() => {
    fetchData({
      path: ENDPOINTS.searchPersons.getUrl({
        page: 0,
        pageSize: PAGE_SIZE,
        query: debouncedQuery,
      }),
    });
  }, [debouncedQuery]);

  const handleInputChange = React.useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(value);
    },
    [],
  );

  const handleDelete = React.useCallback(
    ({ id: personId }: IEventPerson, type: SelectedType) => {
      if (type === 'new') {
        removeNew(personId);
      }

      if (type === 'old') {
        removeOld(personId);
      }
    },
    [removeNew, removeOld],
  );

  const handleApply = React.useCallback(() => {
    onApply?.(personsIds);
  }, [onApply, personsIds]);

  React.useEffect(() => {
    if (!debouncedQuery) {
      resetData();
      return;
    }

    fetchPersons();
  }, [debouncedQuery]);

  useClickOutside({
    ref: searchResultsRef,
    extraRef: inputRef,
    handler: closePopper,
  });

  React.useEffect(() => {
    if (personsData) {
      showPopper();
    }
  }, [personsData]);

  const handleAddPerson = React.useCallback(
    (person: IEventPerson) => {
      addPerson(person, {
        onFoundDuplicates: () => {
          setSnackbarContent({
            type: 'error',
            message: 'Некоторые персоны уже были добавлены',
          });
        },
      });

      closePopper();
    },
    [addPerson, setSnackbarContent, closePopper],
  );

  return (
    <>
      <div className="h-96">
        <FormItem getRootRef={inputRef} className="inline-block">
          <InputSearch
            onSubmit={fetchPersons}
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Добавить персону"
            loading={loadingPersons}
            allowClearButton
            onFocus={showPopper}
          />
        </FormItem>
        {isPopperShown && personsData && (
          <Popper
            getRootRef={searchResultsRef}
            targetRef={inputRef}
            offsetDistance={0}
          >
            <FormItem>
              <Card mode="shadow">
                {personsData.results.length > 0 ? (
                  <ul
                    className="overflow-y-auto"
                    style={{ maxHeight: '416px' }}
                  >
                    {personsData.results.map((person) => (
                      <SearchPersonItem
                        key={person.id}
                        person={person}
                        onClick={handleAddPerson}
                      />
                    ))}
                    {personsData.count > personsData.results.length && (
                      <Cell>
                        +{personsData.count - personsData.results.length}
                      </Cell>
                    )}
                  </ul>
                ) : (
                  <Div>По вашему запросу ничего не найдено</Div>
                )}
              </Card>
            </FormItem>
          </Popper>
        )}
        <FormItem>
          <ul className="flex flex-wrap gap-4">
            {oldPersons.map((person) => (
              <SelectedPersonItem
                key={person.id}
                person={person}
                type="old"
                onDelete={handleDelete}
              />
            ))}
            {newPersons.map((person) => (
              <SelectedPersonItem
                key={person.id}
                person={person}
                type="new"
                onDelete={handleDelete}
              />
            ))}
          </ul>
        </FormItem>
      </div>
      <Div>
        <ButtonGroup align="right" stretched>
          <Button
            className="vkui-edit-button-primary"
            mode="primary"
            size="m"
            appearance="accent"
            onClick={handleApply}
            loading={applying}
          >
            Сохранить
          </Button>
        </ButtonGroup>
      </Div>
    </>
  );
};
