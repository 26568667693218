import { Button } from '@vkontakte/vkui';
import Events from 'components/Events/Events';
import { IEvent } from 'components/Events/types.events';
import * as React from 'react';

type Props = {
  onCheckboxesChange?: (checkedElements: string[]) => void;
	onSelectedEventsChange?: (events: IEvent[]) => void;
  onSave?: (selectedEvents?: IEvent[]) => void;
};

export const EventSelectionModal = ({ onCheckboxesChange, onSave }: Props): React.ReactElement => {
	const [selectedEvents, setSelectedEvents] = React.useState<IEvent[]>([]);

	const handleSave = React.useCallback(() => {
		onSave?.(selectedEvents);
	}, [onSave, selectedEvents]);

	return (
		<div className="h-[610px] overflow-y-auto pt-5 pb-[10px]">
			<Events
				pageSize={8}
				onCheckboxesChange={onCheckboxesChange}
				onSelectedEventsChange={setSelectedEvents}
				action={
					<Button
						className="vkui-edit-button-primary"
						mode="primary"
						size="m"
						align="right"
						onClick={handleSave}
						disabled={selectedEvents.length === 0}
					>
						Добавить
					</Button>
				}
				absolutePositionedFilters
			/>
		</div>
	);
};
