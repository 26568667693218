import * as React from 'react';

export const useFlag = (initial = false) => {
  const [flag, setFlag] = React.useState(initial);

  const setTrue = React.useCallback(() => {
    setFlag(true)
  }, []);

  const setFalse = React.useCallback(() => {
    setFlag(false);
  }, []);

  const toggle = React.useCallback(() => {
    setFlag(prev => !prev);
  }, []);

  return { flag, setFlag, setTrue, setFalse, toggle };
};