import clsx from 'clsx';
import * as React from 'react';

type Props = {
  link?:
    | string
    | {
        to: string;
        target: React.AnchorHTMLAttributes<HTMLAnchorElement>['target'];
      };
  className?: string;
} & Omit<React.HTMLProps<HTMLTableRowElement>, 'onClick'>;

export const Row = ({
  link,
  children,
  className,
  ...props
}: Props): React.ReactElement => {
  const handleClick = React.useMemo(() => {
    if (!link) {
      return undefined;
    }

    if (typeof link === 'string') {
      return () => window.open(link, '_blank');
    }

    return () => window.open(link.to, link.target);
  }, [link]);

  return (
    <tr
      className={clsx(
        'h-10 cursor-pointer transition-colors hover:bg-color-steel-gray-20',
        className,
      )}
      onClick={handleClick}
      {...props}
    >
      {children}
    </tr>
  );
};
