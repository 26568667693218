import { Button } from '@vkontakte/vkui';
import Places from 'components/Places/Places';
import { IPlace } from 'components/Places/types.places';
import * as React from 'react';

type Props = {
	onSave?: (places: IPlace[]) => void;
};

export const PlaceSelectionModal = ({ onSave }: Props): React.ReactElement => {
  const [selectedPlaces, setSelectedPlaces] = React.useState<IPlace[]>([]);

  const handleSave = React.useCallback(() => {
    onSave?.(selectedPlaces);
  }, [onSave, selectedPlaces]);

	return (
		<div className="h-[580px] overflow-y-auto">
			<Places
        selectedPlaces={selectedPlaces}
        setSelectedPlaces={setSelectedPlaces}
				action={
					<Button
						className="vkui-edit-button-primary"
						mode="primary"
						size="m"
						align="right"
						onClick={handleSave}
						disabled={selectedPlaces.length === 0}
					>
						Добавить
					</Button>
				}
				pageSize={8}
				absolutePositionedFilters
			/>
		</div>
	);
};
