import * as React from 'react';

/**
 * @todo refactor to make global
 */
export const useModal = <I extends string = string>() => {
	const [modalData, setModalId] = React.useState<null | {
		id: string;
		element?: React.ReactNode | null;
	}>(null);

	const openModal = React.useCallback(
		(id: I, { element }: { element?: React.ReactNode | null } = {}) =>
			setModalId({ id, element }),
		[],
	);

	const closeModal = React.useCallback(() => setModalId(null), []);

	return {
		modalId: modalData?.id,
		modalElement: modalData?.element,
		isModalOpened: modalData !== null,
		openModal,
		closeModal,
	};
};
