import * as React from 'react';

export const Head = ({ children, ...props }: React.HTMLProps<HTMLTableSectionElement>): React.ReactElement => {
  return (
    <thead {...props}>
      <tr className="border-y-1 border-color-steel-gray-80">
        {children}
      </tr>
    </thead>
  );
};
