import { Icon24ExternalLinkOutline } from '@vkontakte/icons';
import React from 'react';
import { getClearUrl, GetClearUrlParams } from 'lib/utils/getClearUrl';
import { Link } from '@vkontakte/vkui';

const LinkFromApp = ({
  obj,
  type,
  slugOrId,
  app,
}: GetClearUrlParams) => {
  const href = getClearUrl({
    obj,
    type,
    slugOrId,
    app,
  });

  if (!slugOrId || !href) {
    return (
      <div>Ссылка не сформирована</div>
    )
  }

  return (
    <Link
      href={href}
      target="_blank"
    >
      {href}
      <Icon24ExternalLinkOutline
        width={18}
        height={18}
        className="mb-1"
      />
    </Link>
  );
};

export default LinkFromApp;
