import { IEventPerson } from "components/Events/types.events";
import * as React from 'react';
import { Icon20AddCircle } from '@vkontakte/icons';

export const SearchPersonItem = ({
  person,
  onClick,
}: {
  person: IEventPerson;
  onClick?: (person: IEventPerson) => void;
}): React.ReactElement => {
  const handleClick = () => {
    onClick?.(person);
  };

  return (
    <li
      className="flex cursor-pointer items-center gap-2 p-2 hover:text-gray-400"
      onClick={handleClick}
    >
      <Icon20AddCircle width={14} color="#5181B8" />
      {person.full_name}
    </li>
  );
};