import * as React from 'react';

type Params<T extends HTMLElement = HTMLElement, ET extends HTMLElement = HTMLElement> = {
  ref: React.RefObject<T>;
  handler: VoidFunction;
  /**
   * Опциональный дополнительный элемент,
   * по клику на который не срабатывает хэндлер
   */
  extraRef?: React.RefObject<T>;
};

export function useClickOutside<T extends HTMLElement = HTMLElement, ET extends HTMLElement = HTMLElement>({
  ref,
  handler,
  extraRef,
}: Params<T, ET>) {
  React.useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node) || extraRef?.current?.contains(event.target as Node)) {
        return;
      }
      
      handler();
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}