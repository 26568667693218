// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any;

export const debounce = <T extends AnyFunction>(
    f: T,
    timeout = 300,
    startCallback?: VoidFunction
): ((...args: Parameters<T>) => NodeJS.Timeout | null) & { clear: VoidFunction } => {
    let timer: NodeJS.Timeout | null = null;
    let startFunctionFired = false;

    const clear = () => {
        if (timer) {
            clearTimeout(timer);
            startFunctionFired = false;
        }
    };

    const debounced = (...args: Parameters<T>) => {
        if (startCallback && !startFunctionFired) {
            startCallback();
            startFunctionFired = true;
        }

        clear();

        timer = setTimeout(() => {
            f(...args);
            timer = null;
        }, timeout);

        return timer;
    };

    debounced.clear = clear;

    return debounced;
};
