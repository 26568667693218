import * as React from 'react';
import { Title } from '@vkontakte/vkui';
import clsx from 'clsx';

export const HeadCell = ({
  children,
  className,
  ...props
}: React.HTMLProps<HTMLTableHeaderCellElement> & { className?: string }): React.ReactElement => {
  return (
    <th
      {...props}
      className={clsx(
        'whitespace-nowrap py-4 uppercase text-color-gray-600',
        className,
      )}
    >
      <Title
        className="ml-8 flex max-w-fit cursor-pointer items-center gap-1 text-sm"
        level="2"
      >
        {children}
      </Title>
    </th>
  );
};
